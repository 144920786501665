import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import HeroArea from "@containers/hero/layout-10";
import AboutArea from "@containers/about/layout-03";
import SkillArea from "@containers/skill/layout-02";
import ServicesArea from "@containers/service/layout-05";

const IndexPage = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);
    return (
        <Layout pageTitle="Home">
            <main className="main-page-wrapper">
                <div className="rn-slider-area">
                    <div className="container">
                        <div className="row row--30 pt--100 pt_sm--50">
                            <div className="col-lg-6">
                                <HeroArea
                                    data={{
                                        ...content["hero-section"],
                                        socials: data.site.siteMetadata.socials,
                                    }}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="sticky-home-wrapper">
                                    <AboutArea
                                        data={content["about-section"]}
                                    />
                                    <ServicesArea
                                        data={content["service-section"]}
                                    />
                                    <SkillArea
                                        data={content["skill-section"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export const query = graphql`
    query PageQuery {
        site {
            ...Site
        }
        homePage(title: { eq: "andrew-home" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                getform_url: PropTypes.string,
            }),
        }),
        homePage: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default IndexPage;
